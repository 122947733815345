/*--------------------------------

Global styles .css

-------------------------------- */

@font-face {
  font-family: "LibreBaskerville";
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LibreBaskerville";
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "LibreBaskerville";
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "LibreFranklin";
  src: url("../fonts/Libre_Franklin/LibreFranklin-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LibreFranklin";
  src: url("../fonts/Libre_Franklin/LibreFranklin-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "LibreFranklin";
  src: url("../fonts/Libre_Franklin/LibreFranklin-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "LibreFranklin";
  src: url("../fonts/Libre_Franklin/LibreFranklin-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "LibreFranklin";
  src: url("../fonts/Libre_Franklin/LibreFranklin-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "LibreFranklin";
  src: url("../fonts/Libre_Franklin/LibreFranklin-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}
