.btn{
    box-sizing: border-box;
    border-width: $border-thick;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    padding: .5rem $padding-base-horizontal;
    line-height: 1.75;
    margin-right: 3px;
    cursor: pointer;
    text-transform: uppercase;

    &.btn-border,
    &.btn-link{
        background-color: $transparent-bg;
    }

    @include btn-styles($default-color, $default-states-color);
    @include transition($fast-transition-time, linear);

    &:hover,
    &:focus{
        outline: 0 !important;
        @include box-shadow(none);
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
         @include box-shadow(none);
         outline: 0 !important;
    }

    &[class*="btn-outline-"]{
        background-image: none;
        background-color: transparent;
    }
}
.btn-just-icon{
    border-radius: $border-radius-btn-large;
    height: 40px;
    width: 40px;
    min-width: 40px;
    padding: 8px;

    &.btn-sm{
        padding: 4px !important;
    }
    i{
        font-size: $font-size-medium;
        padding: 2px 0px;
    }
}
.upgrade-pro{
    .btn{
        margin-top: 30px;
    }
}
.btn-link.btn-just-icon{
    padding: 8px;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group{
    margin-left: -2px;
}


// Apply the mixin to the buttons
.btn-primary { @include btn-styles($primary-color, $primary-states-color); }
.btn-success { @include btn-styles($success-color, $success-states-color); }
.btn-info    { @include btn-styles($info-color, $info-states-color); }
.btn-warning { @include btn-styles($warning-color, $warning-states-color); }
.btn-danger  { @include btn-styles($danger-color, $danger-states-color); }
.btn-neutral  { @include btn-styles($white-color, $default-states-color); }

.btn-outline-default { @include btn-outline-styles($default-color, $default-states-color); }
.btn-outline-primary { @include btn-outline-styles($primary-color, $primary-states-color); }
.btn-outline-success { @include btn-outline-styles($success-color, $success-states-color); }
.btn-outline-info    { @include btn-outline-styles($info-color, $info-states-color); }
.btn-outline-warning { @include btn-outline-styles($warning-color, $warning-states-color); }
.btn-outline-danger  { @include btn-outline-styles($danger-color, $danger-states-color); }
.btn-outline-neutral { @include btn-outline-styles($white-color, $default-states-color);
    &:hover,
    &:focus{
        color: $default-states-color;
        background-color: $white-color;
    }
}
.btn-neutral {
    @include btn-styles($white-color, $white-color);
    color: $black-color !important;
    &:hover,
    &:focus{
        color: $default-states-color;
    }

    &.btn-border{
        &:hover,
        &:focus{
            color: $black-color;
        }

        &:active,
        &.active,
        .open > &.dropdown-toggle{
             background-color: $white-color;
             color: $black-color;
        }
    }

    &.btn-link:active,
    &.btn-link.active{
        background-color: transparent;
    }
}

.btn{
     &:disabled,
     &[disabled],
     &.disabled{
        @include opacity(.5);
    }
}
.btn-link{
    color: $site-primary-1 !important;
    border-color: transparent !important;
    padding: $padding-base-vertical $padding-base-horizontal;

    &:hover,
    &:focus,
    &:active{
        text-decoration: none;
        border-color: transparent;
    }

    &.btn-icon{
        padding: $padding-base-vertical;
    }
}

.btn-lg{
   @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-base, $line-height-small);
}
.btn-sm{
    @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small);
}
.btn-wd {
    min-width: 140px;
}

.btn-group.select{
    width: 100%;
}
.btn-group.select .btn{
    text-align: left;
}
.btn-group.select .caret{
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 8px;
}
.btn-just-icon{
    &.btn-sm{
        height: 30px;
        width: 30px;
        min-width: 30px;
        padding: 0;

        i{
            font-size: $font-size-small;

        }
    }
    &.btn-lg{
        height: 52px;
        width: 52px;
        min-width: 52px;
        padding: 9px;

        i{
            font-size: 18px;
            padding: 0;
        }

    }

}
.btn-round{
    border-radius: 30px;
}
.btn.btn-link:focus{
    box-shadow: none !important;
    text-decoration: none;
}

.column .btn-link{
    padding: 7px 0;
}
.share-buttons .btn-outline-default{
    margin-top: 24px;
}
#modals .btn-outline-neutral{
    margin-bottom: 10px;
}
.btn-group.select{
    overflow: visible !important;
}
.media{
    .media-body{
        .media-footer{
            .btn-neutral{
               margin: $navbar-margin-btn;
               font-size: $font-size-base;
               i{
                   margin-right: 0 !important;
               }
           }
        }
    }
}
