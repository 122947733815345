.landing-alert {
  margin-bottom: 0;
}
.page-header {
  background-color: transparent;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /* Center vertically */
  align-items: center;
  /*Center horizontaly */
  justify-content: center;

  /*Center horizontaly ie */
  -ms-flex-pack: center;

  .filter::after {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &.page-header-small {
    min-height: 65vh !important;
    max-height: 700px;
  }
  &.page-header-xs {
    min-height: 40vh !important;
  }

  &.section-dark {
    .content-center,
    .category-absolute {
      z-index: 1;
    }
  }
}
.page-header .motto {
  text-align: left;
  z-index: 3;
  color: #fff;
  position: relative;
}
.landing-section {
  padding: 100px 0;
  z-index: 1;
}
.section-buttons {
  z-index: 1;
}
.landing-section .btn-simple {
  padding: 0;
}
.landing-section .column {
  padding: 0 75px 0 25px;
}
.team-player .img-circle,
.team-player .img-thumbnail {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.contact-form {
  margin-top: 30px;
}
.contact-form label {
  margin-top: 15px;
}
.contact-form .btn {
  margin-top: 30px;
}

.navbar-absolute {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 0 10px;
  z-index: 10000;
}

#register-navbar a {
  color: #fff;
}
.register-background .container {
  margin-top: 11%;
  position: relative;
  z-index: 3;
}
.register-footer {
  bottom: 20px;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: transparent;
  color: $white-color;
  left: 0;
}
.register-footer .fa-heart {
  color: #eb5e28;
}

.register-card label {
  margin-top: 15px;
}
.register-card .title {
  color: #b33c12;
  text-align: center;
}
.register-card .btn {
  margin-top: 30px;
}
.register-card .forgot {
  text-align: center;
}

.profile-content {
  position: relative;
}
.owner {
  text-align: center;
}

.text-center {
  text-align: center;
}

.owner .avatar {
  padding: 15px;
  max-width: 180px;
  margin: -85px auto 0;
  display: inline-block;
}
.owner .name h4 {
  margin-top: 10px;
}
.profile-tabs {
  margin: 50px 0;
  min-height: 300px;
}
#following h3 {
  margin: 20px 0 40px 0;
}
#follows .follows .unfollow .checkbox {
  margin-top: -15px;
}
#follows .follows h6 {
  margin-top: 15px;
}
.follows hr {
  margin-top: 10px;
}
.alert h5 {
  // margin-bottom: 10px;
}
.title-brand {
  max-width: 730px;
  margin: 40px auto 30px auto;
  position: relative;
  text-align: center;
  color: #ffffff;
  display: block;

  .type {
    position: absolute;
    font-size: 20px;
    background: #132026;
    padding: 6px 10px;
    border-radius: 4px;
    top: 0;
    font-weight: 600;
    margin-top: 10px;
    right: -15px;
  }
}

.presentation-title {
  font-size: 8em;
  font-weight: 700;
  margin: 0;
  color: #ffffff;
  // background: #fbf8ec;
  background: -moz-linear-gradient(to bottom, #ffffff 35%, #4e6773 100%);
  background: -webkit-linear-gradient(to bottom, #ffffff 35%, #4e6773 100%);
  background: -ms-linear-gradient(to bottom, #ffffff 35%, #4e6773 100%);
  background: -o-linear-gradient(to bottom, #ffffff 35%, #4e6773 100%);
  background: linear-gradient(to bottom, #ffffff 35%, #4e6773 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.presentation-subtitle {
  font-size: 1.7em;
  color: #ffffff;
}

.category-absolute {
  position: absolute;
  text-align: center;
  top: 100vh;
  margin-top: -60px;
  padding: 0 15px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);

  .creative-tim-logo {
    max-width: 140px;
    top: -2px;
    left: 3px;
    position: relative;
  }
}

.fog-low {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -35%;
  margin-bottom: -50px;
  width: 110%;
  opacity: 0.85;

  img {
    width: 100%;
  }

  &.right {
    margin-left: 30%;
    opacity: 1;
  }
}
.page-header {
  .content-center {
    //margin-top: 85px;
  }
}
.moving-clouds {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 250.625em;
  height: 43.75em;
  -webkit-animation: cloudLoop 80s linear infinite;
  animation: cloudLoop 80s linear infinite;
}

@keyframes cloudLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
.following {
  img {
    max-width: 70px;
  }
}

.ie-background {
  .page-header {
    .content-center {
      top: 50%;
      left: 50%;
      position: absolute;
      -ms-transform: translate(-50%, -75%);
      .title-brand {
        .presentation-title {
          background: none;
        }
      }
    }
  }
}

/*
    Custom Variables Changes for MOBILE display
 */
@media (max-width: 768px) {
    .wrapper {
        padding-top: 57px;
    }

    .navbar-burger {
        position: relative;
        /*top: 11px;*/
        right: 20px;
    }

    .navbar-style {
        height: 68px;
    }

    .navbar.navbar-transparent {
        padding-top: 0px;
    }

    .brand-image {
        width: 70px;
    }

    .logo-siaarti {
        width: 90px;
    }

    h1.title-p {
        font-size: 3em;
        margin: 0.7em 0 0;
    }

    .display-mobile-none {
        display: none;
    }

    .card-home-page {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .social-button {
        margin: auto 5px auto 5px !important;
        border: 1px solid transparent;
    }

    .margin-button {
        margin-bottom: 10px;
    }

    .references {
        padding: 50px 20px;
        word-wrap: break-word;
    }

    .references h2 {
        font-size: 4.2vw;
    }

    .references p {
        font-size: 3vw;
        text-justify: auto;
    }

    .references-hr {
        background: #00847c;
        height: 1px;
        width: 100%;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

/*
    Custom Variables Changes for DESKTOP display min width 768px / max width 992px
 */
@media (min-width: 769px) and (max-width: 991px) {
  .wrapper {
    padding-top: 57px;
  }

  .navbar-burger {
    position: absolute;
    top: 17px;
    right: 20px;
  }

  .navbar-style {
    height: 68px;
  }

  .navbar.navbar-transparent {
    padding-top: 15px;
  }

  .brand-image {
    width: 100px;
  }

  .logo-siaarti {
    width: 120px;
  }

  .navbar-menu {
    float: right;
  }

  h1.title-p {
    font-size: 3em;
    margin: 0.7em 0 0;
  }

  .display-desktop-none {
    display: none;
  }

  .card-home-page {
    padding-top: 15px !important;
  }

  .social-button {
    margin: auto 15px auto 15px !important;
    border: 1px solid transparent;
  }

  .references {
    padding: 50px 30px;
    word-wrap: break-word;
  }

  .references h2 {
    font-size: 3vw;
  }

  .references p {
    font-size: 2vw;
    text-justify: auto;
  }

  .references-hr {
    background: #00847c;
    height: 1px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

/*
    Custom Variables Changes for DESKTOP display min width 992px
 */
@media (min-width: 992px) {
  .wrapper {
    padding-top: 82px;
  }

  .navbar-style {
    height: 92px;
  }

  .navbar.navbar-transparent {
    padding-top: 25px;
  }

  .brand-image {
    width: 130px;
  }

  .logo-siaarti {
    width: 160px;
  }

  h1.title-p {
    font-size: 3.5em;
    margin: 0.7em 0 0;
  }

  .display-desktop-none {
    display: none;
  }

  .card-home-page {
    padding-top: 15px !important;
  }

  .social-button {
    margin: auto 15px auto 15px !important;
    border: 1px solid transparent;
  }

  .references {
    padding: 50px 40px;
    word-wrap: break-word;
  }

  .references h2 {
    font-size: 2.1vw;
  }

  .references p {
    font-size: 1.4vw;
    text-justify: auto;
  }

  .references-hr {
    background: #00847c;
    height: 1px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 961px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}

/*
    Custom Variables
 */
.font-weight-extra-bold {
  font-weight: 800 !important;
}
.nav-background {
  background: $white-navbar;
}

.color-primary {
  color: $site-primary-1;
}

.background-primary {
  background: $site-primary-1;
  color: #ffffff;
}

.bg-site-secondary-2 {
  background: $site-secondary-2;
  color: $site-primary-2;
}

.cursor-hand {
  cursor: pointer;
}

.border-title {
  border-bottom: 2px solid $site-primary-1;
}

.nav-border {
  border-bottom: 1px solid $site-primary-1;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-l-r-8 {
  margin: 0px 8px;
}

.margin-l-r-15 {
  margin: auto 15px auto 15px;
}

.padding-0 {
  padding: 0 !important;
}

.padding-t-b-2 {
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14-b {
  font-size: 14px;
  font-weight: bold;
}

.font-size-20-b {
  font-size: 20px;
  font-weight: bold;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.container-page {
  max-width: 1400px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.link-thumbnail:hover img {
  opacity: 0.7;
}

.figure-caption {
  color: $site-primary-2;
  font-size: 16px;
}

.btn-rounded-icon {
  border-radius: 35px;
  font-weight: bold;
  padding: 0.5rem 0.4rem 0.4rem 1.6rem;
  font-size: 18px;
}

.rounded-icon {
  background: transparent;
  height: 50px;
  width: 50px;
  min-width: 50px;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  padding: 13px;
  margin-left: 15px !important;
  margin-right: 3px !important;
  border: 1px solid #ffffff !important;
  border-radius: 50px;
}

.social-icon {
  width: 30px;
  height: 30px;
}

.rounded-icon i {
  font-size: 23px;
}

.btn-rounded-icon-sm {
  border-radius: 35px;
  font-weight: bold !important;
  padding: 0.3rem 0.2rem 0.2rem 1.2rem;
  font-size: 14px;
}

.rounded-icon-sm {
  background: transparent;
  height: 40px;
  width: 40px;
  min-width: 40px;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  padding: 8px;
  margin-left: 7px !important;
  margin-right: 3px !important;
  border: 1px solid #ffffff !important;
  border-radius: 40px;
}

.rounded-icon-sm i {
  font-size: 20px;
}

.pos-absolute-t-l-0 {
  position: absolute;
  top: 0;
  left: 0;
}

// Fade between two images
.fade-in {
  animation-name: fade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 6s;
  animation-direction: alternate;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.letterCircle {
  background: $site-primary-1;
  color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  padding: 1px 5px;
  margin-right: 1px;
  text-align: center;
  font-size: 18px;
}

.logo-msd {
  width: 100px;
}

.pointer-map {
  height: 18px;
}

.social-icons {
  height: 20px;
}

.img-full {
  width: 100%;
  height: auto;
}

.break-word {
  word-wrap: break-word;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

/* Carousel customization */
.home-arrow,
.home-arrow:hover,
.home-arrow:visited,
.home-arrow:active,
.home-arrow:link {
  position: absolute;
  display: block;
  width: 30px !important;
  height: 65px !important;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.home-arrow-prev {
  z-index: 1000;
  left: 0px !important;
}

.home-arrow-next {
  z-index: 1000;
  right: 0px !important;
}

.home-arrow-2,
.home-arrow-2:hover,
.home-arrow-2:visited,
.home-arrow-2:active,
.home-arrow-2:link {
  position: absolute;
  display: block;
  width: 20px !important;
  height: 65px !important;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.home-arrow-prev-2 {
  z-index: 1000;
  left: 10px !important;
}

.home-arrow-next-2 {
  z-index: 1000;
  right: 10px !important;
}

.slick-dots {
  bottom: 30px !important;
}

.slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}

.slick-dots li {
  height: 15px;
  width: 42px !important;
}

.slick-dots li button:before {
  width: 50px !important;
  height: 13px !important;
  font-size: 18px !important;
  background: #cccccc !important;
  color: transparent !important;
  border: 2px solid #222222;
  border-radius: 16px;
  opacity: 0.75 !important;
}

.slick-dots li.slick-active button:before {
  width: 50px;
  height: 13px;
  font-size: 18px;
  background: #00847c !important;
  color: transparent !important;
  border: 2px solid #222222;
  border-radius: 16px;
}

.display-none {
  display: none;
}

.footer {
  background-color: #333333;
  color: #9a9a9a !important;
  padding-top: 15px;
}

.footer .copyright {
  color: #9a9a9a !important;
  padding: 0px 15px;
}

.footer nav > ul a:not(.btn),
.footer nav > ul a:not(.btn):hover,
.footer nav > ul a:not(.btn):visited {
  color: #ffffff !important;
}

.p-patrocinio {
  margin: 0 15px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.p-patrocinio-black {
  margin: 0 15px;
  font-size: 20px;
  font-weight: bold;
  color: #66615b;
}

.navbar-nav {
  margin-right: 70px;
}

.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn),
[class*="bg"] .navbar-nav .nav-item .nav-link:not(.btn) {
  color: #ffffff;
}

.cusrsor-hand {
  cursor: pointer;
}